import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronLeft, ChevronRight, Play, Pause, Shuffle, List, Plus, Filter, Trash2, Volume2 } from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';
import socketIOClient from "socket.io-client";
import { Button } from "../ui/button";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../ui/select";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "../ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Badge } from "../ui/badge";
import ErrorBoundary from '../ErrorBoundary';

import apiClient from '../../apiClient';

const minDelayBetweenWords = 1200; // Minimum delay between words in ms
const additionalDelay = 2400; // Additional delay after both words have been spoken

// const SOCKET_IO_ENDPOINT = "__REACT_APP_SOCKET_IO_ENDPOINT__";
const SOCKET_IO_ENDPOINT = "https://a.language.activatedhuman.earth";
// const SOCKET_IO_ENDPOINT = "http://localhost:8080";

function LanguageLearning() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [wordPairs, setWordPairs] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [word1, setWord1] = useState('');
  const [word2, setWord2] = useState('');
  const [language1Id, setLanguage1Id] = useState('');
  const [language2Id, setLanguage2Id] = useState('');
  const [filterLanguage1Id, setFilterLanguage1Id] = useState('');
  const [filterLanguage2Id, setFilterLanguage2Id] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isShuffled, setIsShuffled] = useState(false);
  const [originalWordPairs, setOriginalWordPairs] = useState([]);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("learn");
  const [searchTerm, setSearchTerm] = useState("");

  const [debugInfo, setDebugInfo] = useState('');

  const audioRef1 = useRef(null);
  const audioRef2 = useRef(null);
  const isPlayingRef = useRef(false);
  const socketRef = useRef(null);
  const isInitializingRef = useRef(false);

  const initializeSocket = useCallback(async () => {
    if (socketRef.current || isInitializingRef.current) {
      return;
    }

    isInitializingRef.current = true;

    try {
      const newSocket = socketIOClient(SOCKET_IO_ENDPOINT, {
        path: '/api/socket.io',
        withCredentials: true,
        transports: ['websocket']
      });

      newSocket.on("connect", async () => {
        console.log("Connected to server");
        try {
          const token = await getAccessTokenSilently();
          newSocket.emit('announce', { token });
        } catch (error) {
          console.error("Error getting access token:", error);
        }
      });

      newSocket.on("authenticated", (data) => {
        if (data.status === 'success') {
          console.log("Successfully authenticated with WebSocket");
        } else {
          console.error("WebSocket authentication failed:", data.message);
        }
      });

      newSocket.on("wordPairAdded", handleWordPairAdded);
      newSocket.on("wordPairDeleted", handleWordPairDeleted);

      newSocket.on("disconnect", (reason) => {
        console.log("Disconnected from server:", reason);
        socketRef.current = null;
      });

      socketRef.current = newSocket;
    } catch (error) {
      console.error("Error initializing socket:", error);
    } finally {
      isInitializingRef.current = false;
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && !socketRef.current) {
      initializeSocket();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [isAuthenticated, initializeSocket]);

  const fetchLanguages = async () => {
    try {
      const response = await apiClient.get('/language/languages');
      console.log('Languages response:', response);
      setLanguages(response.data);
      if (response.data.length >= 2) {
        const enLang = response.data.find(lang => lang.code === 'en');
        const ukLang = response.data.find(lang => lang.code === 'uk');
        if (enLang) setLanguage1Id(enLang.id);
        if (ukLang) setLanguage2Id(ukLang.id);
      }
    } catch (error) {
      console.error('Error fetching languages:', error);
      console.error('Error details:', error.response?.data);
      setError('Failed to fetch languages. Please try again later.');
    }
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchWordPairs = async () => {
    try {
      setError(null);
      const params = {};
      if (filterLanguage1Id) params.language1_id = filterLanguage1Id;
      if (filterLanguage2Id) params.language2_id = filterLanguage2Id;
      const response = await apiClient.get('/language/word_pairs', { params });
      console.log('Word pairs response:', response);
      setOriginalWordPairs(response.data);
      setWordPairs(response.data);
    } catch (error) {
      console.error('Error fetching word pairs:', error);
      setError('Failed to fetch word pairs. Please try again later.');
    }
  };

  useEffect(() => {
    fetchWordPairs();
  }, [filterLanguage1Id, filterLanguage2Id]);

  const handleWordPairAdded = (wordPair) => {
    setWordPairs(prevWordPairs => {
      const newWordPairs = [wordPair, ...prevWordPairs];
      return isShuffled ? shuffleArray(newWordPairs) : newWordPairs;
    });
  };

  const handleWordPairDeleted = (id) => {
    setWordPairs(prevWordPairs => prevWordPairs.filter(wp => wp.id !== id));
  };

  const addWordPair = async () => {
    if (!word1 || !word2) {
      setError("Please enter both words before adding");
      return;
    }
    
    try {
      setError(null);
      const response = await apiClient.post('/language/word_pairs', { 
        word1, 
        word2, 
        language1_id: language1Id, 
        language2_id: language2Id 
      });
      setWordPairs([response.data, ...wordPairs]);
      setWord1('');
      setWord2('');
    } catch (error) {
      console.error('Error adding word pair:', error);
      setError('Failed to add word pair. Please try again.');
    }
  };

  const deleteWordPair = async (id, e) => {
    // Prevent click from propagating to parent elements
    if (e) e.stopPropagation();
    
    try {
      await apiClient.delete(`/language/word_pairs/${id}`);
      setWordPairs(wordPairs.filter(wp => wp.id !== id));
    } catch (error) {
      console.error('Error deleting word pair:', error);
      setError('Failed to delete word pair. Please try again.');
    }
  };

  const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;
    const shuffledArray = [...array];

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [shuffledArray[currentIndex], shuffledArray[randomIndex]] = 
      [shuffledArray[randomIndex], shuffledArray[currentIndex]];
    }

    return shuffledArray;
  };

  const handleShuffle = () => {
    if (isShuffled) {
      // Revert to original order
      setWordPairs([...originalWordPairs]);
      setIsShuffled(false);
    } else {
      // Shuffle the array
      setWordPairs(shuffleArray(wordPairs));
      setIsShuffled(true);
    }
    setCurrentIndex(0);
  };

  const playPause = () => {
    setIsPlaying(prev => !prev);
  };

  const playAudio = (audioRef, url) => {
    return new Promise((resolve) => {
      audioRef.current.src = url;
      audioRef.current.onloadedmetadata = () => {
        audioRef.current.play();
        audioRef.current.onended = resolve;
      };
    });
  };

  const stopAudio = () => {
    setIsPlaying(false);
    isPlayingRef.current = false;
    if (audioRef1.current) {
      audioRef1.current.pause();
      audioRef1.current.currentTime = 0;
    }
    if (audioRef2.current) {
      audioRef2.current.pause();
      audioRef2.current.currentTime = 0;
    }
  };

  const playSingleWord = async (wordPairId, langId, audioRef) => {
    try {
      const audioResponse = await apiClient.get(`/language/word_pair/${wordPairId}/audio?lang=${langId}`, 
        { responseType: 'blob' });
      const audioUrl = URL.createObjectURL(audioResponse.data);
      audioRef.current.src = audioUrl;
      audioRef.current.play();
    } catch (error) {
      console.error('Error playing single word:', error);
    }
  };

  const speakWords = async () => {
    if (wordPairs.length > 0 && isPlayingRef.current) {
      const wordPair = wordPairs[currentIndex];
      try {
        setDebugInfo(`Playing pair: ${wordPair.word1} - ${wordPair.word2}`);
  
        // Use the language IDs from the word pair instead of component state
        const lang1Id = wordPair.language1_id || language1Id;
        const lang2Id = wordPair.language2_id || language2Id;
  
        // Check if we have valid language IDs
        if (!lang1Id || !lang2Id) {
          setDebugInfo(prev => `${prev}\nError: Missing language IDs for word pair`);
          return;
        }
  
        // Fetch first language audio
        setDebugInfo(prev => `${prev}\nFetching audio for language ID: ${lang1Id}`);
        const audioResponse1 = await apiClient.get(`/language/word_pair/${wordPair.id}/audio?lang=${lang1Id}`, { responseType: 'blob' });
        const audioUrl1 = URL.createObjectURL(audioResponse1.data);
  
        // Play first language audio
        await playAudio(audioRef1, audioUrl1);
        setDebugInfo(prev => `${prev}\nPlayed first language audio`);
  
        // Wait for minimum delay
        await new Promise(resolve => setTimeout(resolve, minDelayBetweenWords));
  
        // Fetch second language audio
        setDebugInfo(prev => `${prev}\nFetching audio for language ID: ${lang2Id}`);
        const audioResponse2 = await apiClient.get(`/language/word_pair/${wordPair.id}/audio?lang=${lang2Id}`, { responseType: 'blob' });
        const audioUrl2 = URL.createObjectURL(audioResponse2.data);
  
        // Play second language audio
        await playAudio(audioRef2, audioUrl2);
        setDebugInfo(prev => `${prev}\nPlayed second language audio`);
  
        // Wait for additional delay
        await new Promise(resolve => setTimeout(resolve, additionalDelay));
  
        // Move to next word pair if still playing
        if (isPlayingRef.current) {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % wordPairs.length);
        }
      } catch (error) {
        console.error('Error playing audio:', error);
        setDebugInfo(prev => `${prev}\nError: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    if (isPlaying) {
      isPlayingRef.current = true;
      speakWords();
    } else {
      isPlayingRef.current = false;
    }

    return () => {
      isPlayingRef.current = false;
    };
  }, [currentIndex, isPlaying]);

  const nextWord = () => {
    stopAudio();
    setCurrentIndex((currentIndex + 1) % wordPairs.length);
  };

  const prevWord = () => {
    stopAudio();
    setCurrentIndex((currentIndex - 1 + wordPairs.length) % wordPairs.length);
  };

  const selectWordPair = (index) => {
    stopAudio();
    setCurrentIndex(index);
    // Switch to learn tab
    setActiveTab("learn");
  };

  // Filter word pairs by search term
  const filteredWordPairs = searchTerm 
    ? wordPairs.filter(pair => 
        pair.word1.toLowerCase().includes(searchTerm.toLowerCase()) || 
        pair.word2.toLowerCase().includes(searchTerm.toLowerCase()))
    : wordPairs;

  // Get language names for the current pair
  const getCurrentLanguages = () => {
    if (!wordPairs.length || !languages.length) return { lang1: 'Language 1', lang2: 'Language 2' };
    
    const currentPair = wordPairs[currentIndex];
    const lang1 = languages.find(l => l.id === currentPair.language1_id)?.name || 'Language 1';
    const lang2 = languages.find(l => l.id === currentPair.language2_id)?.name || 'Language 2';
    
    return { lang1, lang2 };
  };

  const { lang1, lang2 } = getCurrentLanguages();

  // Calculate progress percentage
  const progressPercentage = wordPairs.length > 0 
    ? ((currentIndex + 1) / wordPairs.length) * 100 
    : 0;

    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50">
        <div className="container mx-auto px-4 py-6 max-w-6xl">
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg mb-4" role="alert">
              <strong className="font-bold">Error:</strong>
              <span className="block sm:inline ml-1">{error}</span>
            </div>
          )}
          
          <ErrorBoundary>
            <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
              <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                <h1 className="text-2xl font-bold text-indigo-800 mb-4 sm:mb-0">Language Learning</h1>
                <TabsList className="bg-indigo-100">
                  <TabsTrigger value="learn" className="data-[state=active]:bg-indigo-600 data-[state=active]:text-white">
                    Learn
                  </TabsTrigger>
                  <TabsTrigger value="manage" className="data-[state=active]:bg-indigo-600 data-[state=active]:text-white">
                    Manage Words
                  </TabsTrigger>
                  <TabsTrigger value="add" className="data-[state=active]:bg-indigo-600 data-[state=active]:text-white">
                    Add New
                  </TabsTrigger>
                </TabsList>
              </div>
              
              <TabsContent value="learn" className="space-y-4">
                <Card className="shadow-lg">
                  <CardContent className="p-6">
                    {wordPairs.length > 0 ? (
                      <div>
                        <div className="w-full bg-gray-200 h-2 rounded-full mb-6">
                          <div 
                            className="bg-indigo-600 h-2 rounded-full" 
                            style={{ width: `${progressPercentage}%` }}
                          />
                        </div>
                        
                        <div className="flex flex-col md:flex-row justify-center items-center gap-8 mb-8">
                          <div className="w-full md:w-1/2 text-center p-6 bg-white rounded-xl shadow-md">
                            <div className="flex justify-between items-center mb-2">
                              <Badge variant="outline" className="bg-blue-50">{lang1}</Badge>
                              <Button 
                                variant="ghost" 
                                size="sm" 
                                onClick={() => playSingleWord(wordPairs[currentIndex].id, wordPairs[currentIndex].language1_id, audioRef1)}
                                className="text-blue-600"
                              >
                                <Volume2 className="h-4 w-4" />
                              </Button>
                            </div>
                            <h2 className="text-4xl font-bold text-blue-800 mb-2">{wordPairs[currentIndex]?.word1}</h2>
                          </div>
                          
                          <div className="w-full md:w-1/2 text-center p-6 bg-white rounded-xl shadow-md">
                            <div className="flex justify-between items-center mb-2">
                              <Badge variant="outline" className="bg-green-50">{lang2}</Badge>
                              <Button 
                                variant="ghost" 
                                size="sm" 
                                onClick={() => playSingleWord(wordPairs[currentIndex].id, wordPairs[currentIndex].language2_id, audioRef2)}
                                className="text-green-600"
                              >
                                <Volume2 className="h-4 w-4" />
                              </Button>
                            </div>
                            <h2 className="text-4xl font-bold text-green-800 mb-2">{wordPairs[currentIndex]?.word2}</h2>
                          </div>
                        </div>
                        
                        <div className="flex flex-wrap justify-center gap-3">
                          <Button onClick={prevWord} variant="outline" className="flex items-center">
                            <ChevronLeft className="w-4 h-4 mr-1" />
                            Prev
                          </Button>
                          <Button 
                            onClick={playPause} 
                            variant="default" 
                            className={`flex items-center ${isPlaying ? 'bg-red-600 hover:bg-red-700' : 'bg-indigo-600 hover:bg-indigo-700'}`}
                          >
                            {isPlaying ? <Pause className="w-4 h-4 mr-1" /> : <Play className="w-4 h-4 mr-1" />}
                            {isPlaying ? 'Pause' : 'Play'}
                          </Button>
                          <Button onClick={handleShuffle} variant="secondary" className="flex items-center">
                            {isShuffled ? <List className="w-4 h-4 mr-1" /> : <Shuffle className="w-4 h-4 mr-1" />}
                            {isShuffled ? 'Original' : 'Shuffle'}
                          </Button>
                          <Button onClick={nextWord} variant="outline" className="flex items-center">
                            Next
                            <ChevronRight className="w-4 h-4 ml-1" />
                          </Button>
                        </div>
                        
                        <div className="mt-6 text-center text-gray-500 text-sm">
                          Word {currentIndex + 1} of {wordPairs.length}
                        </div>
                      </div>
                    ) : (
                      <div className="text-center py-12">
                        <p className="text-xl text-gray-600">No word pairs available</p>
                        <p className="text-gray-500 mt-2">Add some word pairs to start learning</p>
                        <Button 
                          className="mt-4 bg-indigo-600 hover:bg-indigo-700"
                          onClick={() => setActiveTab("add")}
                        >
                          <Plus className="w-4 h-4 mr-1" />
                          Add Words
                        </Button>
                      </div>
                    )}
                  </CardContent>
                </Card>
                
                {wordPairs.length > 0 && (
                  <Card className="shadow-md">
                    <CardHeader>
                      <CardTitle className="text-lg font-medium">Quick Word List</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                        {wordPairs.slice(0, 12).map((pair, index) => (
                          <Button 
                            key={pair.id} 
                            variant={index === currentIndex ? "default" : "outline"}
                            className={`justify-start overflow-hidden ${index === currentIndex ? 'bg-indigo-600 text-white' : 'text-gray-700'}`}
                            onClick={() => selectWordPair(index)}
                          >
                            <span className="truncate">{pair.word1} - {pair.word2}</span>
                          </Button>
                        ))}
                      </div>
                    </CardContent>
                    {wordPairs.length > 12 && (
                      <CardFooter>
                        <Button 
                          variant="ghost" 
                          className="w-full text-indigo-600"
                          onClick={() => setActiveTab("manage")}
                        >
                          View All Words
                        </Button>
                      </CardFooter>
                    )}
                  </Card>
                )}
              </TabsContent>
              
              <TabsContent value="manage" className="space-y-4">
                <Card className="shadow-lg">
                  <CardHeader>
                    <CardTitle className="flex items-center justify-between">
                      <span>Word Pair Library</span>
                      <Badge variant="outline">{filteredWordPairs.length} words</Badge>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="mb-4">
                      <Input
                        placeholder="Search words..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full"
                      />
                    </div>
                    
                    {filteredWordPairs.length > 0 ? (
                      <div className="grid grid-cols-1 gap-2 max-h-96 overflow-y-auto pr-2">
                        {filteredWordPairs.map((wordPair, index) => (
                          <div 
                            key={wordPair.id} 
                            className={`flex justify-between items-center p-3 rounded-lg cursor-pointer hover:bg-indigo-50 transition ${index === currentIndex ? 'bg-indigo-100 border-l-4 border-indigo-600' : 'bg-white border border-gray-200'}`}
                            onClick={() => selectWordPair(wordPairs.findIndex(wp => wp.id === wordPair.id))}
                          >
                            <div className="flex-1">
                              <div className="flex items-center space-x-2 mb-1">
                                <span className="font-medium text-gray-900">{wordPair.word1}</span>
                                <Button 
                                  variant="ghost" 
                                  size="sm" 
                                  className="h-6 w-6 p-0"
                                  onClick={(e) => playSingleWord(wordPair.id, wordPair.language1_id, audioRef1, e)}
                                >
                                  <Volume2 className="h-3 w-3 text-blue-600" />
                                </Button>
                              </div>
                              <div className="flex items-center space-x-2">
                                <span className="text-gray-700">{wordPair.word2}</span>
                                <Button 
                                  variant="ghost" 
                                  size="sm" 
                                  className="h-6 w-6 p-0"
                                  onClick={(e) => playSingleWord(wordPair.id, wordPair.language2_id, audioRef2, e)}
                                >
                                  <Volume2 className="h-3 w-3 text-green-600" />
                                </Button>
                              </div>
                            </div>
                            <Button 
                              onClick={(e) => deleteWordPair(wordPair.id, e)} 
                              variant="ghost" 
                              size="sm" 
                              className="text-red-500 hover:text-red-700 hover:bg-red-50"
                            >
                              <Trash2 className="w-4 h-4" />
                            </Button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center py-8">
                        <p className="text-gray-500">No word pairs found</p>
                        {searchTerm && (
                          <Button 
                            variant="outline" 
                            className="mt-2"
                            onClick={() => setSearchTerm("")}
                          >
                            Clear Search
                          </Button>
                        )}
                      </div>
                    )}
                  </CardContent>
                  <CardFooter className="flex justify-between">
                    <Button 
                      variant="outline" 
                      onClick={handleShuffle}
                      className="flex items-center"
                    >
                      {isShuffled ? <List className="w-4 h-4 mr-1" /> : <Shuffle className="w-4 h-4 mr-1" />}
                      {isShuffled ? 'Original Order' : 'Shuffle Order'}
                    </Button>
                    <Button 
                      onClick={() => setActiveTab("learn")}
                      className="bg-indigo-600 hover:bg-indigo-700"
                    >
                      <Play className="w-4 h-4 mr-1" />
                      Start Learning
                    </Button>
                  </CardFooter>
                </Card>
              </TabsContent>
              
              <TabsContent value="add">
                <Card className="shadow-lg">
                  <CardHeader>
                    <CardTitle className="flex items-center">
                      <Plus className="w-5 h-5 mr-2" />
                      Add New Word Pair
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    {languages.length > 0 ? (
                      <div className="space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          <div className="space-y-2">
                            <div className="flex items-center justify-between">
                              <Label htmlFor="lang1" className="text-sm font-medium text-gray-700">First Language</Label>
                              <Badge variant="outline" className="bg-blue-50">
                                {languages.find(l => l.id === language1Id)?.name || 'Select Language'}
                              </Badge>
                            </div>
                            <Select
                              value={language1Id}
                              onValueChange={setLanguage1Id}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select language" />
                              </SelectTrigger>
                              <SelectContent>
                                {languages.map(lang => (
                                  <SelectItem key={lang.id} value={lang.id}>
                                    {lang.name} ({lang.code})
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            
                            <Label htmlFor="word1" className="mt-4 text-sm font-medium text-gray-700">First Word</Label>
                            <Input
                              id="word1"
                              value={word1}
                              onChange={(e) => setWord1(e.target.value)}
                              placeholder="Enter word"
                              className="w-full"
                            />
                          </div>
                          
                          <div className="space-y-2">
                            <div className="flex items-center justify-between">
                              <Label htmlFor="lang2" className="text-sm font-medium text-gray-700">Second Language</Label>
                              <Badge variant="outline" className="bg-green-50">
                                {languages.find(l => l.id === language2Id)?.name || 'Select Language'}
                              </Badge>
                            </div>
                            <Select
                              value={language2Id}
                              onValueChange={setLanguage2Id}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select language" />
                              </SelectTrigger>
                              <SelectContent>
                                {languages.map(lang => (
                                  <SelectItem key={lang.id} value={lang.id}>
                                    {lang.name} ({lang.code})
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            
                            <Label htmlFor="word2" className="mt-4 text-sm font-medium text-gray-700">Second Word</Label>
                            <Input
                              id="word2"
                              value={word2}
                              onChange={(e) => setWord2(e.target.value)}
                              placeholder="Enter word"
                              className="w-full"
                            />
                          </div>
                        </div>
                        
                        <Button 
                          onClick={addWordPair} 
                          className="w-full bg-green-600 hover:bg-green-700 text-white"
                          disabled={!word1 || !word2 || !language1Id || !language2Id}
                        >
                          <Plus className="w-4 h-4 mr-2" />
                          Add Word Pair
                        </Button>
                      </div>
                    ) : (
                      <div className="text-center py-8">
                        <p className="text-gray-600">Loading languages...</p>
                        <div className="mt-4 w-8 h-8 border-t-2 border-indigo-600 border-solid rounded-full animate-spin mx-auto"></div>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </TabsContent>
            </Tabs>
          </ErrorBoundary>
        </div>
        
        <audio ref={audioRef1} className="hidden" />
        <audio ref={audioRef2} className="hidden" />
      </div>
    );
  }

  export default LanguageLearning;