import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';

function Auth0Callback() {
  const { isLoading, error, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("isLoading:", isLoading, "isAuthenticated:", isAuthenticated);
    async function handleUserAuthentication() {
      if (!isLoading) {
        if (error) {
          console.error('Auth0 error:', error);
          navigate('/login');
        } else if (isAuthenticated) {
          try {
            const accessToken = await getAccessTokenSilently();
            localStorage.setItem('session_token', accessToken);
            // console.log('Got session token, navigating to /')
            // navigate('/check-subscription'); // Navigate to main app page (while checking subscription)
            navigate('/');
          } catch (err) {
            console.error('Error getting token:', err);
            navigate('/login');
          }
          // if (user.email_verified) {
          //   try {
          //     const accessToken = await getAccessTokenSilently();
          //     localStorage.setItem('session_token', accessToken);
          //     // console.log('Got session token, navigating to /')
          //     navigate('/check-subscription'); // Navigate to main app page (while checking subscription)
          //   } catch (err) {
          //     console.error('Error getting token:', err);
          //     navigate('/login');
          //   }
          // } else {
          //   navigate('/verify-email'); // User needs to verify email
          // }
        }
      }
    }
    handleUserAuthentication();
  }, [isLoading, error, isAuthenticated, user, navigate, getAccessTokenSilently]);

  if (isLoading) {
    return (
      // <div className="min-h-screen flex items-center justify-center bg-gradient-to-b bg-black bg-opacity-100 text-white">
      <div className="min-h-screen bg-gradient-to-b bg-black bg-opacity-100 text-white flex items-center justify-center">
        <div className="flex items-center space-x-2 text-white">
            <Loader2 className="animate-spin" />
            <span>Loading...</span>
        </div>
       </div>
    );
  }

  return null;
}

export default Auth0Callback;
